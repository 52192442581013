export default [
  {
    path: '/dashboard',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/Analytics.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Backoffice',
      layout: 'horizontal',
    },
  },
]
