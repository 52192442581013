import Vue from 'vue'
import { ModalPlugin } from 'bootstrap-vue'
import firebase from 'firebase/compat/app'
import API from '@/store/axios'
import API_cryptos from '@/store/axios_criptos'
import API_wester from '@/store/axios_wester'
import { JSEncrypt } from 'jsencrypt'
import keys from '@/store/keys'
import ReadMore from 'vue-read-more';
import 'firebase/compat/auth'
import * as VueGoogleMaps from 'vue2-google-maps'
// Global Components
import './global-components'
import '@core/scss/vue/pages/maz.scss';
import VueBottomDialog from 'vue-bottom-dialog'
import App from './App.vue'
import store from './store'
import router from './router'
import { MazPhoneNumberInput } from 'maz-ui'
     
// 3rd party plugins
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import './registerServiceWorker'
import LoadScript from 'vue-plugin-load-script';

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')
require('@core/scss/jquery.dataTables.min.scss')

Vue.prototype.$eventBus = new Vue()



// BSV Plugin Registration


Vue.use(LoadScript);

// Composition API
Vue.use(VueBottomDialog)
Vue.use(ReadMore);
Vue.use(MazPhoneNumberInput);
Vue.use(ModalPlugin);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC_a_0lKNN1bFwUzAamxrJE8GEdAU6iEnE',
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  installComponents: true,
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})


Vue.mixin({
  methods: {

    eqcoins(points, motivo, insignia) {
      this.$swal({
        html: '<p class="title_swal">¡ Felicidades ! 🥳</p><img style="width:100px" src="/img/eqcoins.svg"/><p class="p_swal">Recibiste ' + points + " EQCoins por " + motivo + '</p>',
        showCancelButton: false,
        confirmButtonText: 'Entendido',
        focusConfirm: true,
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      });

  


    },exit(userId,tokenAuth, isDashboard){
      


      this.$swal({
        title: "¿Seguro que deseas cerrar sesión?",
        icon: 'question',
        width: '400px',
        showCancelButton: true,
        confirmButtonText: 'Cerrar sesión',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm: () => {
 

          return this.$https.post('/acceso/logout/', {tokenAuth: tokenAuth, userId: this.userId }).then(response => response.data).catch(error => {
            this.$swal.showValidationMessage(
              error,
            )
          })
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
      }).then(result => {

        

        if(result.isConfirmed){


          if (result.value.code == 200) {

          
                    
                    localStorage.removeItem('userData')
                    
                    
       
              
                    
                    

                        

                    
                    this.$swal.close()

                    if(isDashboard){
                      this.$router.push({ name: 'auth-login' })
                    }else{
                      location.reload();
                    }

 
                   
          
                    this.$toast.success('Sesión cerrada exitosamente', {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })

             


    
                  } else {
                    this.$toast.error(result.value.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })

                    if (result.data.code == 401) {
                      
                      localStorage.removeItem('userData')
                      

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                    }
                  }

        }
        



  
      })







    }
  },
})




Vue.config.productionTip = false

const appFirebase = firebase.initializeApp({
  apiKey: "AIzaSyAOYSvpx3lpLVV4ophCwsr768yc4LmzziQ",
  authDomain: "eqcoops.com",
  projectId: "eqcoop-app",
  storageBucket: "eqcoop-app.appspot.com",
  messagingSenderId: "506474282387",
  appId: "1:506474282387:web:52028f3497114e1a90bc66",
  measurementId: "G-BVVKSEWEWZ"
})
const auth = firebase.auth()
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)








const encryptBackoffice = new JSEncrypt()
encryptBackoffice.setPublicKey(keys.publicKeyBackoffice)


const encryptCyptos = new JSEncrypt()
encryptCyptos.setPublicKey(keys.publicKeyCriptomonedas)



const encryptWester = new JSEncrypt()
encryptWester.setPublicKey(keys.publicKeyWester)

const encryptAdmin = new JSEncrypt()
encryptAdmin.setPublicKey(keys.publicKeyAdmin)

const ua = navigator.userAgent

let isMobile=false;

if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
  //es mobile
isMobile=true;
} else {
  //es destopk
  isMobile=false;

}



Vue.prototype.$isMobile = isMobile
Vue.prototype.$https = API
Vue.prototype.$appFirebase = appFirebase
Vue.prototype.$httpsCryptos = API_cryptos
Vue.prototype.$httpsWester = API_wester
Vue.prototype.$encryptBackoffice = encryptBackoffice
Vue.prototype.$encryptCyptos = encryptCyptos
Vue.prototype.$encryptWester = encryptWester
Vue.prototype.$encryptAdmin = encryptAdmin


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
