import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes

import apps from './routes/apps'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import administracion from './routes/administracion'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...apps,
    ...dashboard,
    ...pages,
    ...administracion,
    {
      path: '*',
      redirect: '404',
    },
  ],
})

router.beforeEach((to, _, next) => {

  var condition = navigator.onLine ? "online" : "offline";

  if(condition === "online"){


    if (to.matched.some(record => record.meta.requiresAuth)) {
      const user = localStorage.getItem('userData')
    
      if (user) {
        try {
  
          const datos_json = JSON.parse(user);
  
      
          let token=datos_json.tokenAuth;
  
        
          if (typeof token === "undefined" || token === "") {
  
            localStorage.removeItem('userData')
  
  
            return next({ name: 'auth-login' })
  
          }else{
  
            
  
            Vue.prototype.$tokenGlobal= token;
  
            let t=datos_json.t;
  
        
            if(typeof t === "undefined" || t === ""){
              
              localStorage.removeItem('userData')
  
  
              return next({ name: 'auth-login' })
             
      
            }else{
  
              
              const is_incorrect = isNaN(t)
  
              if (is_incorrect) {
                
                localStorage.removeItem('userData')
  
                
  
             
                
                
  
                return next({ name: 'auth-login' })
              }
  
              const currentDate = new Date().getTime()
              if (Number(currentDate) > Number(t)) {
                
                localStorage.removeItem('userData')
  
                
  
             
                
                
  
  
                return next({ name: 'auth-login' })
  
              }else{
         
  
                Vue.prototype.$userGlobal= user;
  
          
  
            
                let hasPassword=datos_json.hasPassword;
  
    
                  if(hasPassword){
               
  
                    return next()
                  
                  }else{
  
                  
  
                    if (to.path == '/password'){
                      return next()
                    }else{
  
                      return next({ name: 'password' })
                      
                    }
  
                   
                  
                  }
          
        
  
  
                
              }
  
  
            }
  
          }
  
          
        
        } catch (error3) {
          // eliminar storage
          // redirect login
          
          localStorage.removeItem('userData')
  
          
  
          
          
  
             
          
          
  
          return next({ name: 'auth-login' })
        }
      } else {
        // eliminar storage
        // redirect login
        
        
  
             
        
        
  
        return next({ name: 'auth-login' })
      }
    } else {
      
  
      if (to.path == '/login' || to.path == '/') {
  
        const user = localStorage.getItem('userData')
  
        if (user) {
          
          const datos_json = JSON.parse(user);
  
      
          let token=datos_json.tokenAuth;
  
          if (typeof token === "undefined" || token === "") {
  
            localStorage.removeItem('userData')
  
                 
            
            
  
            return next()
           
      
          }else{
            
  
            
            Vue.prototype.$tokenGlobal= token;
  
            let t=datos_json.t;
  
            if(typeof t === "undefined" || t === ""){
              
              localStorage.removeItem('userData')
    
              
    
               
              
              
    
              return next()
      
            }else{
  
              
  
              Vue.prototype.$userGlobal= user;
  
  
              const is_incorrect = isNaN(t)
  
              if (is_incorrect) {
                
                localStorage.removeItem('userData')
  
                
  
             
                
                
  
                return next()
              }
  
              const currentDate = new Date().getTime()
              if (Number(currentDate) > Number(t)) {
       
                
                localStorage.removeItem('userData')
  
                
  
             
                
                
  
  
  
                return next()
  
              }
  
              return next({ name: 'dashboard-analytics' })
  
  
  
            }
  
  
  
          }
  
         
        }else{
  
          
          
    
               
          
          
  
          return next()
        }
  
      
      }
      
       // Vue.prototype.$userGlobal= "";
      //  Vue.prototype.$tokenGlobal= "";
      
  
      return next()
    }

  }else{

   
    window.location.href = window.location.protocol + "//" + window.location.host+"/conexion.html";
  }

 

  
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
