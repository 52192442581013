export default [
  {
    path: '/dashboard/wallets',
    name: 'wallets',
    id: 'wallets',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/wallets/Wallet.vue'),
  },
  {
    path: '/dashboard/nfts',
    name: 'nfts',
    id: 'nfts',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/wallets/NFTS.vue'),
  },
  {
    path: '/dashboard/perfil',
    name: 'perfil',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/profile/Perfil.vue'),
  },  {
    path: '/dashboard/recargar',
    name: 'recargar',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/recargas/RecargarWallet.vue'),
  },{
    path: '/dashboard/marketplace',
    name: 'marketplace',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/marketplace/Index.vue'),
  }, {
    path: '/dashboard/marketplace/:id?',
    name: 'marketplaceparam',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/marketplace/Details.vue'),
  },{
    path: '/dashboard/remesas',
    name: 'remesas',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/remesas/Index.vue'),
  },{
    path: '/dashboard/referidos',
    name: 'referidos',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/referidos/Index.vue'),
  },{
    path: '/dashboard/negocio',
    name: 'negocio',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/negocio/Index.vue'),
  },{
    path: '/dashboard/rangos',
    name: 'rangos',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/referidos/Rangos.vue'),
  },{
    path: '/dashboard/tutoriales',
    name: 'tutoriales',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/tutoriales/Index.vue'),
  },{
    path: '/dashboard/retiros',
    name: 'retiros',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/retiro/Retirar.vue'),
  },{
    path: '/dashboard/transacciones',
    name: 'transacciones',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/transacciones/Transacciones.vue'),
  },{
    path: '/dashboard/transferencias',
    name: 'transferencias',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/transferencias/Transferencias.vue'),
  },{
    path: '/dashboard/otros',
    name: 'otros',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/others/menu.vue'),
  },{
    path: '/dashboard/notificaciones',
    name: 'notificaciones',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/notificaciones/Notificaciones.vue')
  },{
    path: '/dashboard/solicitudes',
    name: 'solicitudes',
    meta: { requiresAuth: true, layout: 'horizontal', },
    component: () => import('@/views/pages/solicitudes/Index.vue')
  },

]
