export default [
    {
      path: '/administracion',
      name: 'administracion',
      id: 'administracion',
      meta: { requiresAuth: true, layout: 'horizontal', },
      component: () => import('@/views/administracion/Index.vue'),
    },
    
  
  ]