<template>
  <div
    id="app"
    class="h-100"
  >

    <component :is="layout">
 
      <router-view />
    </component>
 
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook



const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutFull,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
 
    layout() {
  
      if (this.$route.meta.layout === 'full' || this.$route.meta.layout === undefined){
        return 'layout-full'
      }else{
        return `layout-${this.contentLayoutType}`
      } 
    
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
   
    document.body.classList.remove('dark-layout')

 
  },
  setup() {


 
    return {
     
    }
  },mounted(){



    
  },    methods: {

   

  }
}
</script>
