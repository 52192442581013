export default [
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/u/:alias?',
    name: 'billetera',
    component: () => import('@/views/pages/billetera/Billetera.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    },
  },
  {
    path: '/m',
    name: 'marketplacePublic',
    component: () => import('@/views/pages/marketplacePublico/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    },
  },
  {
    path: '/m/:id?',
    name: 'marketplacePublicDetail1',
    component: () => import('@/views/pages/marketplacePublico/Details.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    },
  },
  {
    path: '/m/:id?/:promoCode?',
    name: 'marketplacePublicDetail2',
    component: () => import('@/views/pages/marketplacePublico/Details.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    },
  },
  {
    path: '/invitacion/:promoCode?',
    name: 'invitacion',
    component: () => import('@/views/pages/referidos/Invitacion.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth'
    },
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('@/views/pages/password/Index.vue'),
    meta: {
      requiresAuth: true,
      layout: 'full',
      resource: 'Auth'
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  }, {
    path: '/politica-de-privacidad',
    name: 'privacidad',
    component: () => import('@/views/pages/PoliticaPrivacidad.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  }, {
    path: '/instrucciones-eliminacion-datos',
    name: 'eliminacion',
    component: () => import('@/views/pages/Eliminacion.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },{
    path: '/exito',
    name: 'exito',
    component: () => import('@/views/pages/exitoPaypal.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },{
    path: '/cancelado',
    name: 'cancelado',
    component: () => import('@/views/pages/canceladoPaypal.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
